<template>
  <div>

    <Head />

    <nav aria-label="breadcrumb" v-if="PostData">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item" v-if="PostData.Category"><router-link
              :to="{ name: 'Category', params: { CId: PostData.Category.CId } }">{{ PostData.Category.CategoryName
              }}</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ PostData.Post.Title }}</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper list_main" v-if="PostData">
      <div class="container">
        <div class="title_box">
          <h1>{{ PostData.Post.Title }}</h1>
        </div>
        <div class="social_box">
          <ul class="social">
            <li><a :href="PostData.ShareUrl_Facebook"><img src="img/icon_fb.svg" alt=""></a></li>
            <li><a :href="PostData.ShareUrl_Line"><img src="img/icon_line.svg" alt=""></a></li>
            <li><a :href="PostData.ShareUrl_Twitter"><img src="img/icon_twitter.svg" alt=""></a></li>
          </ul>
          <p class="time">{{ PostData.Post.PublicStartTime | timeString("YYYY/MM/DD") }}</p>
        </div>
        <div class="carousel_box">
          <slick class="carousel_list_main" v-if="PostData.Post.Image || Slideshows" :options="{ dots: true }">
            <!-- <div class="box" v-if="PostData.Post.Image">
              <img :src="PostData.SiteUrl + PostData.Post.Image" :alt="PostData.Post.ImageAlt" />
            </div> -->
            <div class="box" v-for="(Slideshow, index) in Slideshows" :key="index">
              <img :src="PostData.SiteUrl + Slideshow.Url" :alt="Slideshow.DisplayName" />
            </div>
          </slick>
        </div>
        <div class="text_box" v-html="PostData.Post.Content">
        </div>
        <hr class="line">
        <div class="download_box" v-if="Attachments">
          <h2>檔案下載</h2>
          <a :href="PostData.SiteUrl +
            '/Platform/Html/Download?FId=' +
            Attachment.FId
            " target="_blank" :title="'下載' + Attachment.DisplayName + ', 另開新視窗'"
            v-for="( Attachment, index ) in Attachments " :key="index">{{ Attachment.DisplayName }}
          </a>
        </div>
      </div>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import Slick from "vue-slick";

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Post",
  components: {
    Head,
    Footer,
    Slick
  },
  metaInfo() {
    if (!this.PostData) {
      return;
    }
    return {
      //title: "",
      meta: [
        {
          name: 'description',
          content: this.PostData.Post.SimpleContent,
        },
        {
          property: 'og:title',
          content: this.PostData.Post.Title,
        },
      ],
    };
  },
  data() {
    return {
      PostData: null,
      Attachments: null,
      Slideshows: null,
      local: "zh_tw",
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
        {
          params: { PId: to.params.PId, CId: to.params.CId },
        }
      )
      .then((response) => {
        this.PostData = response.data;
        if (this.PostData.Post.Attachment != "[]") {
          this.Attachments = JSON.parse(this.PostData.Post.Attachment);
        } else {
          this.Attachments = null;
        }
        if (this.PostData.Post.Slideshow != "[]") {
          this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
        } else {
          this.Slideshows = null;
        }
        if (this.PostData.Post.Tags && this.PostData.Post.Tags != "[]") {
          this.TagData = $.parseJSON(this.PostData.Post.Tags);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
        {
          params: { PId: this.$route.params.PId, CId: this.$route.params.CId },
        }
      )
      .then((response) => {
        this.PostData = response.data;
        if (this.PostData.Post.Attachment != "[]") {
          this.Attachments = JSON.parse(this.PostData.Post.Attachment);
        } else {
          this.Attachments = null;
        }
        if (this.PostData.Post.Slideshow != "[]") {
          this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
        } else {
          this.Slideshows = null;
        }
        if (this.PostData.Post.Tags && this.PostData.Post.Tags != "[]") {
          this.TagData = $.parseJSON(this.PostData.Post.Tags);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("thinker_site_lang")) {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = $cookies.get("thinker_site_lang");
    } else {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    copyUrl() {
      const currentUrl = window.location.href;
      navigator.clipboard.writeText(currentUrl).then(() => {
        Swal.fire({
          title: "複製成功",
          icon: "success",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500,
        });
      }).catch(err => {
        Swal.fire({
          title: "錯誤",
          text: err,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
      });
    }
  },
};
</script>
